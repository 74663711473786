html {
    overflow-x: hidden
}

body {
    background-color: #231E2A;
    color: #F8F7FF;
    font-size: 16px;
    line-height: 1.5;
    margin: 0;
    padding: 0;
}

body.home{
    background-color: #0A0A0B;
}

* {
    box-sizing: border-box;
    font-family: 'AlbertSans', 'Poppins', sans-serif;
}

.cursor-pointer {
    cursor: pointer;
}

.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right {
    display: none !important
}

.mapboxgl-map {
    font: unset !important;

    .detail-popup.mapboxgl-popup {
        max-width:fit-content !important;
        
        .mapboxgl-popup-content {
            background: none !important;
            backdrop-filter: none !important;
            box-shadow: none !important;
            display: flex;
            justify-content: center;
    
            .map-popup {
                background-color:rgba(18, 18, 18, .75);
                padding:5px;
                border-radius: 8px;;
                text-align: center;
                .map-popup__username {
                    font-size:14px;
                }
                .map-popup__status {
                    font-size:11px; 
                    color:#a3a3a3
                }
            }
        }
    }
}



/* width */
::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgb(170, 170, 170);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.reacttooltip {
    z-index: 9999 !important;
    background: #2F285B !important;
    opacity: 1 !important;
    border-radius: 99px !important;
    padding:8px 16px !important;
    font-size:12px !important;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25) !important;
}

.reacttooltip::after, .reacttooltip::before {
    display: none;
}


/*line chart*/
.linechart_path {
    stroke-width: 2; // Thick Line
    fill: none; // No Fill
}

.linechart_axis {
    stroke: #bdc3c7; // Grey Axis Color
}

.extlink{
    color:#9B8FF6;
    text-decoration: none;

    &:hover{
        text-decoration: underline;
    }
}


.mapboxgl-popup{
    z-index: 11;
}